<template>
  <div>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th :class="{ 'pl-0': noPadding }">
              Filnavn
            </th>
            <th>Størrelse</th>
            <th>Dato</th>
            <th />
          </tr>
        </thead>
        <tbody v-if="files.length">
          <tr
            v-for="(item, index) in files"
            :key="index"
            class="text-caption"
            :style="rowStyle"
          >
            <td
              :class="{ 'pl-0': noPadding }"
              @click="deletable ? null : download(item.id)"
            >
              {{ item.fileName }}
            </td>
            <td @click="deletable ? null : download(item.id)">
              {{ item.fileSizeInkB }} kB
            </td>
            <td
              style="min-width:110px"
              @click="deletable ? null : download(item.id)"
            >
              {{ item.createdOn | formatDateTime }}
            </td>
            <td class="pr-1 text-right">
              <v-btn
                color="secondary"
                icon
                @click="download(item.id)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>

              <v-btn
                v-if="deletable"
                color="secondary"
                icon
                @click.stop="showConfirmDeleteDialog = true; indexOfFileToDelete = index"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-caption">
            <td
              :class="{ 'pl-0': noPadding }"
              colspan="4"
            >
              Ingen filer vedhæftet denne aftale
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog
      v-model="showConfirmDeleteDialog"
      max-width="300"
      persistent
    >
      <v-card>
        <v-card-title class="warning">
          Er du sikker?
        </v-card-title>
        <v-card-text class="mt-4">
          Filen vil blive slettet permanent fra aftalen!
        </v-card-text>
        <v-card-actions class="mt-4">
          <v-spacer />
          <v-btn
            outlined
            @click="showConfirmDeleteDialog = false"
          >
            Annuller
          </v-btn>
          <v-btn
            color="warning"
            :loading="deleting"
            @click="removeFile"
          >
            Ja, slet fil
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fileHandler from '@/mixins/fileHandler'

export default {
  name: 'FileList',
  mixins: [
    fileHandler
  ],
  props: {
    baseUrl: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    deletable: {
      type: Boolean,
      default: () => false
    },
    noPadding: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      showConfirmDeleteDialog: false,
      indexOfFileToDelete: null,
      deleting: false,
      rowStyle: {
        cursor: this.deletable ? null : 'pointer',
        backgroundColor: this.deletable ? 'transparent' : null
      }
    }
  },
  computed: {
    sanitizedBaseUrl() {
      return this.baseUrl.endsWith('/') ? this.baseUrl.slice(0, -1) : this.baseUrl;
    }
  },
  methods: {
    download(fileId) {
      this.streamFile(`${this.sanitizedBaseUrl}/download/${fileId}`);
    },
    async removeFile() {
      this.deleting = true;

      try {
        await this.$AuthService.api.delete(`${this.baseUrl}/${this.files[this.indexOfFileToDelete].id}`);

        this.files.splice(this.indexOfFileToDelete, 1); // Since files are passed by reference, mutating it here affects the files in the parent component
        this.showConfirmDeleteDialog = false;
        this.indexOfFileToDelete = null;
      } catch (error) {
        alert(`${this.$t('errors.deleteFile')} ${this.$t('errors.tryAgain')}`);
        console.error(error);
      } finally {
        this.deleting = false;
      }
    }
  }
}
</script>