<template>
  <div>
    <div
      ref="message"
      id="message-body"
      v-html="html"
      :style="[ collapsed ? {'max-height': collapsedHeight + 'px'} : {'max-height' : expandedHeight + 'px'} ]"
    />
    <div class="d-flex justify-end">
      <v-btn
        v-if="showReadMore"
        text
        small
        @click="collapsed = !collapsed"
      >
        {{ buttonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadMore',
  props: {
    html: {
      type: String,
      required: true
    },
    collapsedHeight: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      collapsed: true,
      expandedHeight: null,
      showReadMore: false
    }
  },
  computed: {
    buttonText() {
      return this.collapsed ? 'Vis mere' : 'Vis mindre';
    }
  },
  mounted() {
    this.expandedHeight = this.$refs.message.scrollHeight;
    this.showReadMore = this.expandedHeight > this.collapsedHeight;
  }
}
</script>

<style>
#message-body {
  transition: all 200ms ease-in-out;
  overflow-y: clip;
}
</style>
