<template>
  <v-card elevation="2">
    <v-toolbar flat>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <v-spacer />

      <div class="d-flex align-center" style="gap: 1rem">
        <v-switch
          v-model="showSystemMessages"
          label="Vis systembeskeder"
          hide-details
        />
        <v-btn icon @click="getPDF">
          <v-icon> mdi-file-pdf-outline </v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-card-text>
      <v-timeline
        align-top
        dense
      >
        <v-timeline-item
          :color="$vuetify.theme.dark ? 'grey darken-2' : 'white'"
          fill-dot
        >
          <template #icon>
            <v-icon small :color="$vuetify.theme.dark ? 'grey lighten-2' : 'secondary'">
              {{ entryTemplate.icon }}
            </v-icon>
          </template>
          <v-slide-x-transition mode="out-in">
            <v-card v-if="showNewNote">
              <v-card-text class="text--primary">
                <v-textarea
                  v-model="entryTemplate.text"
                  label="Besked"
                  outlined
                  clearable
                  clear-icon="mdi-close-circle"
                  auto-grow
                  autofocus
                  rows="3"
                  @keydown="addEntryKeyDown"
                  hide-details=""
                ></v-textarea>
                <v-datetime-picker
                  v-if="$store.getters.currentUser.organizationKind == 'Internal'"
                  useIso
                  label="Andet tidspunkt end nu"
                  v-model="entryTemplate.entryDateTime"
                  date-format="yyyy-MM-dd"
                  time-format="HH:mm:ss"
                  :time-picker-props="timeProps"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                  <template
                    slot="actions"
                    slot-scope="{ parent }"
                  >
                    <v-btn
                      outlined
                      color="primary"
                      @click.native="parent.cancelHandler"
                    >
                      Annuller
                    </v-btn>
                    <v-btn
                      color="secondary"
                      @click="parent.okHandler"
                    >
                      OK
                    </v-btn>
                  </template>
                </v-datetime-picker>
              </v-card-text>
              <v-card-actions>
                <v-checkbox
                  v-if="$store.getters.currentUser.organizationKind == 'Internal'"
                  v-model="entryTemplate.isInternal"
                  dense
                  label="Marker som intern note"
                />
                <v-spacer />
                <v-btn
                  text
                  @click="showNewNote = false; resetEntryTemplate()"
                >
                  Annuller
                </v-btn>
                <v-btn
                  color="secondary"
                  :disabled="!entryTemplate.text"
                  :loading="addingNote"
                  outlined
                  @click="addEntry"
                >
                  Send
                  <v-icon right>
                    mdi-send
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-btn
              v-else
              class="mb-4"
              color="primary"
              outlined
              @click="showNewNote = true"
            >
              Ny besked
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-slide-x-transition>
        </v-timeline-item>

        <v-slide-y-transition group>
          <v-timeline-item
            v-for="(item, i) in computedEntries"
            :key="i"
            :color="$vuetify.theme.dark ? 'grey darken-2' : 'white'"
            fill-dot
          >
            <template #icon>
              <v-icon small :color="$vuetify.theme.dark ? 'grey lighten-2' : 'secondary'">
                {{ item.icon }}
              </v-icon>
            </template>
            <v-card
              :class="{
                'teal lighten-5': !$vuetify.theme.dark && item.type === 'Message' && item.createdByOrganization === $store.getters.userOrganizationName,
                'grey lighten-3': !$vuetify.theme.dark && item.type === 'Message' && item.createdByOrganization !== $store.getters.userOrganizationName,
                'cyan darken-3': $vuetify.theme.dark && item.type === 'Message' && item.createdByOrganization === $store.getters.userOrganizationName,
                'grey darken-3': $vuetify.theme.dark && item.type === 'Message' && item.createdByOrganization !== $store.getters.userOrganizationName,
              }"
            >
              <v-card-title>
                <div class="d-flex justify-space-between align-start" style="width: 100%">
                  <div class="text-body-2">
                    {{ item.title }}
                  </div>
                  <v-icon
                    v-if="item.isInternal"
                    color="secondary lighten-2"
                  >
                    mdi-eye-off-outline
                  </v-icon>
                  <v-icon
                    v-if="item.type !== 'Message'"
                    color="secondary lighten-2"
                  >
                    mdi-robot-outline
                  </v-icon>
                </div>
              </v-card-title>
              <v-card-subtitle v-if="item.createdByUserFullNameWithDetails" class="text-caption">
                {{ item.createdByUserFullNameWithDetails }}
              </v-card-subtitle>
              <v-card-text>
                <ReadMore v-if="item.text" :html="item.text" />
                <div class="text-caption text-right mt-4 mb-n2 grey--text">
                  {{ item.entryDateTime | formatDateTime }}
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-slide-y-transition>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import ReadMore from '@/components/ReadMore.vue'
import fileHandler from '@/mixins/fileHandler';

export default {
  name: 'ActivityLog',
  components: {
    ReadMore
  },
  mixins: [fileHandler],
  props: {
    title: {
      type: String,
      default: () => 'Sagsforløb'
    },
    addEndpoint: {
      type: String,
      default: 'messages'
    },
    entryTemplate: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
        type: 'Message',
        icon: 'mdi-message-outline',
        entryDateTime: new Date(),
        isInternal: false,
        relationId: null,
        relationKind: null
      })
    },
    entries: {
      type: Array,
      default: () => ([])
    },
    closed: {
      type: Boolean,
      default: false
    },
    relationId: {
      type: String,
      required: true
    },
    relationKind: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showSystemMessages: true,
      showNewNote: false,
      addingNote: false,
      timeProps: {
        format:"24hr",
        useSeconds: true,
      }
    }
  },
  computed: {
    computedEntries() {
      if (!this.showSystemMessages) {
        return this.entries.filter(entry => entry.type === 'Message');
      }

      else {
        return this.entries;
      }
    }
  },
  methods: {
    resetEntryTemplate() {
      this.entryTemplate.text = '';
      this.entryTemplate.entryDateTime = null;
      this.entryTemplate.isInternal = false;
    },
    addEntryKeyDown(e) {
      if (e.key==="Escape") {
        this.resetEntryTemplate();
      }

      if ((e.key === "s" && e.altKey) || (e.keyCode === 13 && e.ctrlKey)) {
        e.preventDefault();
        this.addEntry();
      }
    },
    async addEntry() {
      this.addingNote = true;

      if (this.entryTemplate.hasOwnProperty('relationId')) {
        this.entryTemplate.relationId = this.relationId;
      }

      if (this.entryTemplate.hasOwnProperty('relationKind')) {
        this.entryTemplate.relationKind = this.relationKind;
      }

      try {
        const response = await this.$AuthService.api.post(this.addEndpoint, this.entryTemplate);

        if (!response.data.success) throw new Error(response.data.errors);

        this.entries.unshift(response.data.model);
        this.showNewNote = false;
        this.resetEntryTemplate();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Der skete en fejl og beskeden er ikke blevet gemt. Prøv venligst igen.' });
      } finally {
        this.addingNote = false;
      }
    },

    async getPDF() {
      try {
        const response = await this.$AuthService.api.get('messages/generate-pdf', {
          params: {
            relationId: this.relationId,
            relationKind: this.relationKind,
            includeSystemMessages: this.showSystemMessages
          },
          responseType: 'arraybuffer'
        });

        this.streamFile(null, response)
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Der skete en fejl. PDF\'en kunne ikke skabes. Prøv venligst igen.' });
      }
    }
  }
};
</script>