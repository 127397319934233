<template>
  <span>
    <router-link
      v-if="model"
      :class="{ 'white--text': $vuetify.theme.dark }"
      :to="{path: '/location', query: { textSearch: model.title, includeHistory: true}}"
    ><span>{{ model.title }}</span></router-link>
    <v-tooltip
      v-if="model"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          small
          style="cursor:help"
          v-bind="attrs"
          color="grey"
          v-on="on"
        >mdi-information-outline</v-icon>
      </template>
      <span>
        {{ model.address }}<br>
        {{ model.country }}<br>
        {{ model.notes }}
      </span>
    </v-tooltip>
  </span>
</template>

<script>

export default {
  name: 'LocationLink',
  props: {
    id: null
  },
  data() {
    return {
      model: null
    }
  },
  mounted() {
    let vm = this;

    vm.$AuthService.api
      .get(`location/link/${this.id}`)
      .then((response) => {
        vm.model = response.data;
      });
  }
}
</script>